// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  Create,
  ImageInput,
  ImageField,
  FormDataConsumer,
  AutocompleteInput,
  SelectInput,
  Button as Buttons,
  downloadCSV,
  FileInput,
  FileField,
  useCreate,
  DateTimeInput,
} from "react-admin";
import mbaas from "../../../provider/Mbaas";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Box, Card, CardMedia, Grid, Hidden, IconButton, Stack, TextField } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Papa from "papaparse";
import jsonExport from "jsonexport/dist";
import ReactJson from "react-json-view";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import { RichTextInput } from "ra-input-rich-text";
import linkToList from "./linkToList";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const CreateBroadcast = ({ onCancel, ...props }) => {
  const classes = useStyles();

  const getLinkToList = async (country: string, platform: string[]) => {
    const resp = await mbaas.client.invokeApi("console-webview-link-list", {
      country: country,
      platform: platform,
    });

    if (resp.error) {
      return [{ url: "custom_url", label: "CUSTOM URL" }];
    }
    resp.data.push({ url: "custom_url", label: "CUSTOM URL" });
    return resp?.data;
  };

  const [country, setCountry] = useState("");
  const [platform, setPlatform] = useState("");
  const [link, setLink] = useState([]);

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const data = await getLinkToList(country, [platform]);
        setLink(data);
      } catch (error) {
        console.error("Error fetching links:", error);
      }
    };
    fetchLink();
  }, [country, platform]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  // const onSubmitHandler = async (data) => {
  //   await dataProvider.update("promotionsBroadcast", { data, id }).then(() => {
  //     notify("ra.notification.created", {
  //       type: "info",
  //       messageArgs: { smart_count: 1 },
  //       undoable: true,
  //     });
  //     redirect(`/promotions/broadcast`);
  //     refresh();
  //     Swal.fire("Success!", "", "success");
  //   });
  // };

  const [targetList, setTargetList] = React.useState<any>([{ id: "", label: "" }]);
  const [targetSearch, setTargetSearch] = React.useState("");
  const [value, setValue] = React.useState([]);
  const [targetType, setTargetType] = React.useState("");
  const [dataForm, setDataForm] = React.useState({});

  async function fetchTarget() {
    let res;
    let where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (targetSearch) {
      filterArray.push({
        phone: {
          _ilike: `%${targetSearch}%`,
        },
      });
    }

    // if (account_type === "SIM" && targetSearch) {
    //   filterArray.push({
    //     msisdn: {
    //       _ilike: `%${targetSearch}%`,
    //     },
    //   });
    // }
    if (filterArray.length > 0) where._and = filterArray;

    let { allUsersList } = await mbaas.client.gql.query({
      query: `
        query usersList ($where: UsersWhereInput) {
          allUsersList (limit:10, where:$where) {
            data {
              id
              phone
            }
            count
          }
        }
        `,
      variables: {
        where,
      },
    });

    const newTargetList = allUsersList?.data?.map((dat: any) => {
      return {
        id: dat.phone,
        label: dat.phone,
      };
    });

    setTargetList(newTargetList);
  }

  async function fetchAllTarget(target_type: any) {
    console.log("FETCH ALL TARGET: ", target_type);

    let where: Record<string, any> = {};
    const filterArray: any[] = [
      {
        phone: {
          _is_null: false,
        },
      },
      {
        roles: {
          _has_keys_any: ["Subscriber"],
        },
      },
      // M21
      ...(targetType === "M21_ALL"
        ? [
            {
              is_m21: {
                _eq: true,
              },
            },
          ]
        : []),
      ...(targetType === "M21_HK"
        ? [
            {
              is_m21: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "HK",
              },
            },
          ]
        : []),
      ...(targetType === "M21_TW"
        ? [
            {
              is_m21: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "TW",
              },
            },
          ]
        : []),
      ...(targetType === "M21_MY"
        ? [
            {
              is_m21: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "MY",
              },
            },
          ]
        : []),
      ...(targetType === "M21_ID"
        ? [
            {
              is_m21: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "ID",
              },
            },
          ]
        : []),
      // G2
      ...(targetType === "G2_ALL"
        ? [
            {
              is_grapari: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "TW",
              },
            },
          ]
        : []),
      // GHK
      ...(targetType === "GHK_ALL"
        ? [
            {
              is_grapari: {
                _eq: true,
              },
            },
            {
              country: {
                _eq: "HK",
              },
            },
          ]
        : []),
      // HASH_TS
      ...(targetType === "HASH_TS"
        ? [
            {
              phone: {
                _in: ["601137627101", "85254259878"],
              },
            },
          ]
        : []),
    ];

    if (filterArray.length > 0) where._and = filterArray;

    let { allUsersList } = await mbaas.client.gql.query({
      query: `
        query usersList ($where: UsersWhereInput) {
          allUsersList(where: $where, limit: 99999999, offset: 0){
            data {
              id
              phone
              email
              virtual_msisdn_user_id_list_aggregate{
                count
              }
              is_m21
              is_grapari
              country
            }
            count
          }
        }
        `,
      variables: {
        where,
      },
    });

    let filteredData = [];

    if (targetType === "M21_ALL_NO_VN") {
      filteredData = allUsersList?.data?.filter((el: any) => el?.virtual_msisdn_user_id_list_aggregate?.count === 0).filter((dt: any) => dt?.is_m21 === true);
    } else if (targetType === "M21_ALL_VN_ONLY") {
      filteredData = allUsersList?.data?.filter((el: any) => el?.virtual_msisdn_user_id_list_aggregate?.count > 0).filter((dt: any) => dt?.is_m21 === true);
    } else {
      filteredData = allUsersList?.data;
    }

    console.log(filteredData, "RAW");

    const newTargetList = filteredData
      .map((dat: any) => {
        return {
          id: dat.phone,
          label: dat.phone,
        };
      })
      .filter((el: any) => el.label !== "0" && !el.label.toString().startsWith("0") && el.label !== "null" && !el.label.toString().startsWith("+"));

    console.log(newTargetList, "SMACKDOWN");

    if (targetType !== "CUSTOM") {
      setValue(newTargetList);
    } else {
      setValue([]);
    }
  }

  useEffect(() => {
    console.log("Matoha");

    fetchTarget();
  }, [targetSearch]);

  useEffect(() => {
    fetchAllTarget(targetType);
  }, [targetType]);

  const typeList = [
    { id: "M21_ALL", name: "M21 - ALL SUBSCRIBERS" },
    { id: "M21_HK", name: "M21 - HONG KONG SUBSCRIBERS" },
    { id: "M21_TW", name: "M21 - TAIWAN SUBSCRIBERS" },
    { id: "M21_MY", name: "M21 - MALAYSIA SUBSCRIBERS" },
    { id: "M21_ID", name: "M21 - INDONESIA SUBSCRIBERS" },
    { id: "M21_ALL_VN_ONLY", name: "M21 - ALL SUBSCRIBERS (HAVE VN)" },
    { id: "M21_ALL_NO_VN", name: "M21 - ALL SUBSCRIBERS (DO NOT HAVE VN)" },
    { id: "G2_ALL", name: "G2 - ALL SUBSCRIBERS" },
    { id: "GHK_ALL", name: "Grapari HK - ALL SUBSCRIBERS" },
    { id: "HASH_TS", name: "HASH - TS TEAM" },
    { id: "CUSTOM", name: "CUSTOM TARGET" },
  ];

  const [openCsvDialog, setOpenCsvDialog] = React.useState(false);
  const [fileCsv, setFileCsv] = React.useState<any>("");
  const [uploadedFileCsv, setUploadedFileCsv] = React.useState("");
  const [specificSubscriber, setSpecificSubscriber] = React.useState([]);

  const ToolbarEdit = () => {
    return (
      <Toolbar classes={useStyles()}>
        <SaveButton />
      </Toolbar>
    );
  };

  const onSubmitHandler = async () => {
    const newValue = value?.map((dat: any) => {
      return dat?.label;
    });

    let topic = {
      M21_ALL: "M21_ALL_SUBSCRIBERS",
      M21_HK: "M21_ALL_SUBSCRIBERS_HK",
      M21_TW: "M21_ALL_SUBSCRIBERS_TW",
      M21_MY: "M21_ALL_SUBSCRIBERS_MY",
      M21_ID: "M21_ALL_SUBSCRIBERS_ID",
      M21_ALL_VN_ONLY: "M21_VN_SUBSCRIBERS",
      M21_ALL_NO_VN: "M21_NON_VN_SUBSCRIBERS",
      G2_ALL: "G2_ALL_SUBSCRIBERS",
      GHK_ALL: "GHK_ALL_SUBSCRIBERS",
      HASH_TS: "HASH_TS",
      CUSTOM: "CUSTOM",
    };

    const newData = {
      bc: "BROADCAST",
      title: dataForm.title,
      content: dataForm?.content,
      scheduled_at: dataForm?.scheduled_at ? dataForm?.scheduled_at : null,
      url: dataForm?.custom_url ? dataForm?.custom_url : dataForm?.url,
      image: dataForm?.urlThumbnail?.rawFile,
      topic: topic[targetType],
      target: newValue,
    };
    await dataProvider
      .create("promotionsBroadcast", {
        data: { ...newData },
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotions/broadcast`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  const handleCloseCsvDialog = () => {
    setOpenCsvDialog(false);
  };

  const handleOpenCsvDialog = () => {
    setOpenCsvDialog(true);
  };

  const handleUploadCsv = () => {
    const reader = new FileReader();
    reader.onload = async ({ target }: any) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns: any = parsedData
        .flat()
        .map((item: any) => item.phone)
        .filter((item: any) => item);
      if (!columns.length) {
        notify(`1st line in CSV file should be 'phone'!`, { type: "warning" });
        return;
      }
      const dataTarget = columns?.map((item: any) => ({
        id: item,
        label: item,
      }));
      setSpecificSubscriber(columns);
      setValue(dataTarget);
      setFileCsv(null);
      notify(`CSV file uploaded successfully!`);
    };
    reader.readAsText(fileCsv);
  };

  const uploadedCsv = async () => {
    let filename = `spec-subs-phone`;
    if (uploadedFileCsv !== "") filename = uploadedFileCsv;
    let jsonObject = [{ phone: "" }];
    if (specificSubscriber?.length) {
      jsonObject = specificSubscriber.map((phone) => {
        return { phone: phone };
      });
    }
    jsonExport(jsonObject, {}, (err: any, csv: any) => {
      downloadCSV(csv, filename);
    });
  };

  const ToolbarCsvDialog = () => {
    return <></>;
  };

  const handleTargetChange = (event) => {
    const target = event.target.value;
    let [platform, country] = target.split("_");

    switch (platform) {
      case "M21":
        country = ["HK", "TW", "MY", "ID"].includes(country) ? country : "HK,TW,MY,ID";
        break;

      case "G2":
        country = "TW";
        break

      case "GHK":
        country = 'HK'
        break

      default:
        country = "empty"
        break;
    }
    setCountry(country);
    setPlatform(platform);
    setTargetType(target);
  };
  return (
    <>
      <Dialog open={openCsvDialog} onClose={handleCloseCsvDialog}>
        <DialogTitle>CSV File</DialogTitle>
        <DialogContent dividers>
          <SimpleForm toolbar={<ToolbarCsvDialog />}>
            <Buttons startIcon={<DescriptionIcon />} onClick={uploadedCsv} label={specificSubscriber.length ? "See Uploaded CSV" : "Download Master CSV"} variant="outlined" />
            <br />
            {specificSubscriber.length ? (
              <DialogContentText>
                <ReactJson src={specificSubscriber || []} collapsed={true} enableClipboard={false} displayDataTypes={false} displayObjectSize={true} groupArraysAfterLength={10} name={false} />
                <br />
              </DialogContentText>
            ) : (
              ""
            )}
            <FileInput
              source="files"
              options={{ multiple: false }}
              onChange={(e) => {
                setFileCsv(e);
                const filenameArray = e?.name.split(".");
                filenameArray?.pop();
                setUploadedFileCsv(filenameArray?.join("."));
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Buttons onClick={handleCloseCsvDialog} label="Close"></Buttons>
          <Buttons onClick={handleUploadCsv} startIcon={<UploadFileIcon />} label="Upload" autoFocus disabled={!fileCsv} />
        </DialogActions>
      </Dialog>
      <Create resource="promotionsBroadcast" title=" &rsaquo; Create" {...props}>
        <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }} height="145vh">
          <Stack direction="row" p={0} sx={{ position: "absolute", right: 0, top: 25, zIndex: 100 }}>
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <SimpleForm onSubmit={onSubmitHandler} toolbar={<ToolbarEdit />}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput fullWidth className={classes.input} source="title" data-testid="content-title" label="Title" validate={required()} />
                </Grid>
                <div style={{ display: "none" }}>
                  <TextInput source={"bc"} hidden={true} defaultValue={"BROADCAST"} />
                </div>
                <Grid item xs={12}>
                  <SelectInput
                    fullWidth
                    className={classes.input}
                    source="type"
                    validate={[required()]}
                    choices={typeList}
                    // value={targetType}
                    // defaultValue={"ALL"}
                    label="Target"
                    onChange={(event) => {
                      handleTargetChange(event);
                    }}
                    data-test-id="country"
                  />
                  <FormDataConsumer>
                    {({ formData }: any) =>
                      formData.type === "CUSTOM" && (
                        <Grid container justifyContent="flex-end">
                          <Buttons label="CSV File" data-testid="csv-file" variant="contained" onClick={handleOpenCsvDialog}>
                            <PostAddIcon />
                          </Buttons>
                        </Grid>
                      )
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteInput source="url" label="Link to" choices={link} optionText="label" optionValue="url" fullWidth validate={required()} data-test-id="uri" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimeInput fullWidth source="scheduled_at" label="Schedule At" />
                </Grid>
                <Grid item xs={6}>
                  <FormDataConsumer>
                    {({ formData }: any) =>
                      formData.url === "custom_url" && (
                        <Grid item xs={12}>
                          <TextInput fullWidth source="custom_url" label="Custom Url" validate={required()} />
                        </Grid>
                      )
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12}>
                  <TextInput fullWidth className={classes.input} source="content" data-testid="content-content" label="Content" validate={required()} />
                </Grid>
                <Grid item xs={12}>
                  <ImageInput data-test-id="image" source="urlThumbnail" label="Main Image" accept="image/*" options={{ multiple: false }}>
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
              </Grid>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setDataForm(formData);
                }}
              </FormDataConsumer>
            </div>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};
