import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
export class PromotionsBroadcastProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query PromotionsBroadcastList($where: BroadcastMessagesWhereInput) {
          allBroadcastMessagesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              content
              target
              scheduled_at
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    let returnedData = queryResult?.allBroadcastMessagesList?.data;
    // if (params.filter.title) {
    //   returnedData = returnedData.filter((x: any) =>
    //     x.content.title.includes(params.filter.title)
    //   );
    // }
    // if (params.filter.content) {
    //   returnedData = returnedData.filter((x: any) =>
    //     x.content.content.includes(params.filter.content)
    //   );
    // }
    return {
      data: returnedData,
      total: queryResult?.allBroadcastMessagesList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aBroadcastMessage($id: UUID) {
          getBroadcastMessagesById(id: $id) {
            id
            created_at
            updated_at
            content
            target
            scheduled_at
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: queryResult?.getBroadcastMessagesById };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: BroadcastMessagesUpdateInput!) {
          updateBroadcastMessagesById(ids: $ids, input: $input) {
            id
            content
            target
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return {
      data: queryResult?.updateBroadcastMessagesById[0],
    } as UpdateResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });
    let submittedData = {};
    if (params.data?.bc === "BROADCAST") {
      console.log("BROADCAST--MENU");

      const rawImage = params?.data?.image;

      let urlImg = "";

      if (rawImage) {
        const response: any = await this.client.storage.upload({
          file: rawImage,
          params: {
            filename: `bannersPromo-${Date.now()}`,
            bucket: "banners",
          },
        });

        const download = await this.client.storage.downloadUrl({
          params: {
            bucket: "banners",
          },
          fileId: response?.data.id,
        });

        urlImg = download.data.url.split("?");
      }

      submittedData = {
        content: {
          content: params?.data.content,
          url: params?.data.url,
          image: urlImg ? urlImg[0] : null,
          title: params?.data.title,
          topic: params?.data.topic,
        },
        target: params?.data.target,
        scheduled_at: params?.data.scheduled_at ? params?.data.scheduled_at : null
      };
    } else {
      console.log("BANNERS--MENU");
      submittedData = {
        content: {
          content: params?.data.content,
          url: params?.data.url,
          image: params?.data.image,
          title: params?.data.title,
          topic: params?.data.topic,
        },
        target: params?.data.target,
        scheduled_at: params?.data.scheduled_at ? params?.data.scheduled_at : null
      };
    }
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [BroadcastMessagesCreateInput!]!) {
          createBroadcastMessages(input: $input) {
            id
            content
            target
            scheduled_at
          }
        }
      `,
      variables: {
        input: submittedData,
      },
    });

    return { data: queryResult?.createBroadcastMessages[0] } as CreateResult;
  }
}
