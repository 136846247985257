// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  SelectInput,
  BooleanInput,
  useRecordContext,
  DateTimeInput,
  FormDataConsumer,
  DeleteWithConfirmButton,
  FunctionField,
  RaRecord,
  Button,
  FileInput,
  FileField,
  downloadCSV,
  AutocompleteArrayInput,
  useGetOne,
  AutocompleteInput,
  // NumberInput
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import moment from "moment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Papa from "papaparse";
import jsonExport from "jsonexport/dist";
import DescriptionIcon from "@mui/icons-material/Description";
import ReactJson from "react-json-view";
import { useFormContext } from "react-hook-form";
import Mbaas from "../../../provider/Mbaas";
import { isEmpty, omit } from "lodash";
import genRandomChar from "../../../utils/genRandomChar";

const today = moment().toISOString();

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

const ToolbarEdit = () => {
  const record = useRecordContext();
  let disabled = false;
  if (record.start < today) disabled = true;
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton alwaysEnable />
      <DeleteWithConfirmButton
        redirect={`/promotions/flashsale`}
        disabled={disabled}
      />
    </Toolbar>
  );
};

const ToolbarCsvDialog = () => {
  return <></>;
};

const AutocompleteUrlInput = ({ record }) => {
  const [urlOption, setUrlOption] = useState([]);

  useEffect(() => {
    const getUrlList = async () => {
      if (isEmpty(record.country) && isEmpty(record.configs.platform)) {
        setUrlOption([]);
        return
      }
      const resp = await Mbaas.client.invokeApi("console-webview-link-list", {
        country: record.country,
        platform: record.configs.platform,
      });
      if (resp.data) {
        setUrlOption(resp.data.map((m) => ({ id: m.url, name: m.label })));
      } else {
        setUrlOption([]);
      }
    };
    getUrlList();
  }, [record.country, record.configs.platform]);

  return (
    <AutocompleteInput
      label="Url"
      source="bonus.url"
      fullWidth
      choices={urlOption}
      defaultValue={record?.items?.bonus?.url}
      disabled={record.start < today}
    />
  );
};

export const EditFlashsale = ({ onCancel, id, ...props }) => {
  const classes = useStyles();

  const [optionItemGroup, setOptionItemGroup] = useState([]);
  const [optionItemKey, setOptionItemKey] = useState([]);

  const [selectedItemGroup, setSelectedItemGroup] = useState([]);
  const [selectedItemKey, setSelectedItemKey] = useState([]);

  const [optionTargetItemGroup, setOptionTargetItemGroup] = useState([]);
  const [optionTargetItemKey, setOptionTargetItemKey] = useState([]);

  const [selectedTargetItemGroup, setSelectedTargetItemGroup] = useState([]);
  const [selectedTargetItemKey, setSelectedTargetItemKey] = useState([]);

  const [firstRender, setFirstRender] = useState(true);

  const [specSubSx, setSpecSubSx] = useState(12);

  const [openCsvDialog, setOpenCsvDialog] = useState(false);
  const [fileCsv, setFileCsv] = useState("");
  const [uploadedFileCsv, setUploadedFileCsv] = useState("");
  const [specificSubscriber, setSpecificSubscriber] = useState([]);

  const { data, isLoading } = useGetOne(
    "promotionsFlashsale",
    { id: id },
    { enabled: !!id }
  );

  const [isNewSubs, setIsNewSubs] = useState(data?.configs.new_subscriber_only || false);
  const [isComebackSubs, setIsComebackSubs] = useState(
    data?.configs.comeback_subscriber || false
  );
  const [isProductiveSubs, setIsProductiveSubs] = useState(
    data?.configs.ever_productive_subscriber || false
  );
  const [isCashback, setIsCashback] = useState((data?.configs?.cashback?.status && data?.configs?.cashback?.status !== undefined) || false)
  const [isBonus, setIsBonus] = useState((data?.items?.bonus?.code !== "" && data?.items?.bonus?.code !== undefined) || false)
  const [bonusCode, setBonusCode] = useState(data?.items?.bonus?.code || "");
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const notify = useNotify();

  const handleCloseCsvDialog = () => {
    setOpenCsvDialog(false);
  };

  const handleOpenCsvDialog = () => {
    setOpenCsvDialog(true);
  };

  const handleBonusCode = () => {
    const BonusCodeGenerate = genRandomChar(10).toUpperCase()
    setBonusCode(BonusCodeGenerate)
  };

  const handleUploadCsv = () => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = parsedData
        .flat()
        .map((item) => item.phone)
        .filter((item) => item);
      if (!columns.length) {
        notify(`1st line in CSV file should be 'phone'!`, { type: "warning" });
        return;
      }
      setSpecificSubscriber(columns);
      setFileCsv(null);
      notify(`CSV file uploaded successfully!`);
    };
    reader.readAsText(fileCsv);
  };

  const uploadedCsv = async () => {
    let filename = `spec-subs-phone`;
    if (uploadedFileCsv !== "") filename = uploadedFileCsv;
    let jsonObject = [{ phone: "" }];
    if (specificSubscriber?.length) {
      jsonObject = specificSubscriber.map((phone) => {
        return { phone: phone };
      });
    }
    jsonExport(jsonObject, {}, (err: any, csv: any) => {
      downloadCSV(csv, filename);
    });
  };

  if (!isLoading) {
    data['is_bonus'] = (data?.items?.bonus?.code !== "" && data?.items?.bonus?.code !== undefined);
  }

  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      dataProvider
        .getList("servicesProduct", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: {
            field: "label",
            order: "ASC",
          },
        })
        .then((v) => {
          const items = v.data;
          const itemGroups = [...new Set(items)]
            ?.filter(
              (v, i, a) =>
                a.findIndex((t) => t.item_group === v.item_group) === i
            )
            .filter(
              (x) => !["bpjs", "plnbill"].includes(x.item_group.toLowerCase())
            )
            .map((v) => ({
              id: v.item_group,
              name: v.item_group,
            }))
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          const itemKeys = [...new Set(items)]
            .filter((u) =>
              selectedItemGroup.map((t) => t.id).includes(u.item_group)
            )
            .map((v) => ({
              id: `${v.item_key} (${v.label})`,
              name: `${v.item_key} (${v.label})`,
            }));
          setOptionItemGroup(itemGroups);
          setOptionItemKey(itemKeys);
          !firstRender && setSelectedItemKey(itemKeys);
        });
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [id, firstRender, selectedItemGroup]);

  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      dataProvider
        .getList("servicesProduct", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: {
            field: "label",
            order: "ASC",
          },
        })
        .then((v) => {
          const items = v.data;
          const itemGroups = [...new Set(items)]
            ?.filter(
              (v, i, a) =>
                a.findIndex((t) => t.item_group === v.item_group) === i
            )
            .filter(
              (x) => !["bpjs", "plnbill"].includes(x.item_group.toLowerCase())
            )
            .map((v) => ({
              id: v.item_group,
              name: v.item_group,
            }))
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          const itemKeys = [...new Set(items)]
            .filter((u) =>
              selectedTargetItemGroup.map((t) => t.id).includes(u.item_group)
            )
            .map((v) => ({
              id: `${v.item_key} (${v.label})`,
              name: `${v.item_key} (${v.label})`,
            }));
          setOptionTargetItemGroup(itemGroups);
          setOptionTargetItemKey(itemKeys);
          !firstRender && setSelectedTargetItemKey(itemKeys);
        });
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [id, firstRender, selectedTargetItemGroup]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const handleNotFound = () => {
    Swal.fire("Subscriber Not Found", "", "error")
  }

  const handlePercentage = () => {
    Swal.fire("Percentage Is More Than 1", "", "error")
  }

  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (dataSubmit) => {
    let configsSpecSubs = [] as Array<{
      id: string;
      name: string;
      phone: string;
    }>;

    if (
      dataSubmit.configs.specific_subscriber.status === true &&
      specificSubscriber?.length
    ) {
      const getSubs = await dataProvider.getList("subscribers", {
        filter: {
          phone: specificSubscriber,
        },
        sort: {
          field: "phone",
          order: "asc",
        },
        pagination: {
          page: 1,
          perPage: 999999,
        },
      });
      configsSpecSubs = getSubs.data.map((subs) => {
        return { id: subs.id, name: subs.name, phone: subs.phone };
      });
      if (!configsSpecSubs.length || (specificSubscriber.length !== configsSpecSubs.length)) {
        return handleNotFound();
      }
    }

    if (
      dataSubmit.configs.specific_subscriber.status === true &&
      !specificSubscriber?.length
    ) {
      dataSubmit.configs.specific_subscriber.status = false;
    }

    if(dataSubmit.cashback.percentage && (dataSubmit.cashback.percentage > 1)) {
      return handlePercentage();
    }

    const { platforms, ...otherData } = dataSubmit;
    let submitData;

    if (data.start < today) {
      submitData = {
        start: dataSubmit.start,
        end: dataSubmit.end
      }
    } else {
      submitData = {
        ...omit(otherData, ["bonus", "is_bonus", "cashback"]),
        value: Number(dataSubmit.value),
        configs: {
          ...{
            ...dataSubmit.configs,
            ever_productive_subscriber: isProductiveSubs,
            new_subscriber_only: isNewSubs,
            comeback_subscriber: isComebackSubs,
            cashback: {
              status: (dataSubmit.cashback.percentage && dataSubmit.cashback.unit) ? dataSubmit.cashback.status : false,
              percentage: dataSubmit.cashback.percentage ? `${dataSubmit.cashback.percentage}` : "",
              unit: dataSubmit.cashback.unit || "",
            }
          },
          platform: platforms,
          specific_subscriber: {
            status: dataSubmit.configs.specific_subscriber.status,
            ...(configsSpecSubs.length
              ? { subscribers: configsSpecSubs }
              : {}),
          },
        },
        items: {
          item_groups: selectedItemGroup.map((x) => {
            return x.id;
          }),
          item_keys: selectedItemKey.map((x) => {
            return x.id.split(" (")[0];
          }),
          bonus: {
            item_groups: selectedTargetItemGroup.map((x) => {
              return x.id;
            }),
            item_keys: selectedTargetItemKey.map((x) => {
              return x.id.split(" (")[0];
            }),
            product_name: dataSubmit.bonus?.product_name || "",
            code: dataSubmit.is_bonus ? bonusCode : "",
            url: dataSubmit.bonus?.url || "",
            usage: dataSubmit.items?.bonus?.usage || []
          },
        }
      }
    }

    console.log({ submitData }, 'CEK submitData')

    await dataProvider
      .update("promotionsFlashsale", {
        data: submitData,
        id,
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotions/flashsale`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  return (
    <Edit
      resource="promotionsFlashsale"
      title=" &rsaquo; Edit"
      {...props}
      component="div"
    >
      <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          // sx={{ width: "100%" }}
          onSubmit={onSubmitHandler}
          toolbar={<ToolbarEdit />}
        >
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <SelectInput
                      label="Country"
                      source="country"
                      fullWidth
                      validate={required()}
                      choices={[
                        { id: "HK", name: "Hongkong" },
                        { id: "ID", name: "Indonesia" },
                        { id: "TW", name: "Taiwan" },
                        { id: "MY", name: "Malaysia" },
                      ]}
                      value={selectedCountry}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                      }}
                      data-testid="input-country"
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => {
                    if (typeof record.configs.platform === "string") {
                      return (
                        <AutocompleteArrayInput
                          label="Platform"
                          source="platforms"
                          defaultValue={[record.configs.platform]}
                          fullWidth
                          validate={required()}
                          value={selectedPlatform}
                          onChange={(value) => {
                            setSelectedPlatform(value);
                          }}
                          choices={[
                            { id: "M21", name: "M21" },
                            { id: "G2", name: "G2" },
                            { id: "GHK", name: "GHK" },
                          ]}
                          data-testid="input-platform"
                          disabled={record.start < today}
                        />
                      );
                    }
                    return (
                      <AutocompleteArrayInput
                        label="Platform"
                        source="platforms"
                        defaultValue={record.configs.platform}
                        fullWidth
                        validate={required()}
                        choices={[
                          { id: "M21", name: "M21" },
                          { id: "G2", name: "G2" },
                          { id: "GHK", name: "GHK" },
                        ]}
                        data-testid="input-platform"
                        disabled={record.start < today}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ItemGroupAutocomplete
                  label="Item Groups"
                  source="items.item_groups"
                  validate={required()}
                  option={optionItemGroup}
                  selectedItemGroup={selectedItemGroup}
                  setSelectedItemGroup={setSelectedItemGroup}
                  setSelectedItemKey={setSelectedItemKey}
                  firstRender={firstRender}
                  setFirstRender={setFirstRender}
                  today={today}
                />
              </Grid>
              <Grid item xs={12}>
                <ItemKeyAutocomplete
                  label="Item Keys"
                  source="items.item_keys"
                  validate={required()}
                  option={optionItemKey}
                  selectedItemKey={selectedItemKey}
                  setSelectedItemKey={setSelectedItemKey}
                  firstRender={firstRender}
                  setFirstRender={setFirstRender}
                  today={today}
                />
              </Grid>
              <Grid item xs={12}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      fullWidth
                      source="is_bonus"
                      data-testid="is_bonus"
                      label="Add Bonus?"
                      onChange={(e) =>
                        setIsBonus(e.target.checked)
                      }
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              {isBonus &&
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Bonus Item</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ItemTargetGroupAutocomplete
                      label="Target Item Groups"
                      source="items.bonus.item_groups"
                      validate={required()}
                      option={optionTargetItemGroup}
                      selectedTargetItemGroup={selectedTargetItemGroup}
                      setSelectedTargetItemGroup={setSelectedTargetItemGroup}
                      setSelectedTargetItemKey={setSelectedTargetItemKey}
                      firstRender={firstRender}
                      setFirstRender={setFirstRender}
                      today={today}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ItemTargetKeyAutocomplete
                      label="Target Item Keys"
                      source="items.bonus.item_keys"
                      validate={required()}
                      option={optionTargetItemKey}
                      selectedTargetItemKey={selectedTargetItemKey}
                      setSelectedTargetItemKey={setSelectedTargetItemKey}
                      firstRender={firstRender}
                      setFirstRender={setFirstRender}
                      today={today}
                      data-testid="input-bonus-item-keys"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <TextInput
                          fullWidth
                          className={classes.input}
                          source="bonus.product_name"
                          defaultValue={record?.items?.bonus?.product_name}
                          data-testid="input-bonus-product-name"
                          label="Product Name"
                          disabled={record.start < today}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <BonusCodeInput
                          source="bonus.code"
                          value={bonusCode}
                          setBonusCode={setBonusCode}
                          disabled={record.start < today}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ display: "flex" }}>
                    <FunctionField
                      sx={{
                        alignContent: "center"
                      }}
                      render={(record: RaRecord) => (
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: "#0488d1",
                            margin: "8px 0px 3px 0px",
                            padding: "15px 10px 15px 10px",
                            ":hover": { color: "white", backgroundColor: "#0488d1" }
                          }}
                          onClick={handleBonusCode}
                          label="Generate"
                          disabled={record.start < today}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <AutocompleteUrlInput record={record} />
                      )}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      fullWidth
                      source="cashback.status"
                      label="Add Cashback?"
                      disabled={record.start < today}
                      defaultValue={isCashback}
                      onChange={(e) =>
                        setIsCashback(e.target.checked)
                      }
                    />
                  )}
                />
              </Grid>
              {isCashback &&
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Cashback Item</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <TextInput
                          fullWidth
                          className={classes.input}
                          source="cashback.percentage"
                          defaultValue={record?.configs?.cashback?.percentage || ""}
                          label="Percentage (example: 0.5)"
                          inputProps={{ pattern: "[0-9.]*" }}
                          disabled={record.start < today}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FunctionField
                      render={(record: RaRecord) => (
                        <SelectInput
                          label="Unit"
                          source="cashback.unit"
                          fullWidth
                          defaultValue={record?.configs?.cashback?.unit || ""}
                          choices={[
                            { id: "COIN", name: "Coin ( Balance )" },
                            { id: "POINT", name: "Point" },
                          ]}
                          disabled={record.start < today}
                        />
                      )}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.once_per_target"
                      label="1x / MSISDN?"
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.new_subscriber_only"
                      label="For New Subs. Only?"
                      title="Subs. / MSISN without success transactions before this promo is created"
                      data-testid="input-new-subscriber-only"
                      disabled={record.start < today}
                      checked={isNewSubs}
                      onChange={(event) => {
                        setIsNewSubs(event.target.checked);
                        setIsProductiveSubs(false);
                        setIsComebackSubs(false);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={specSubSx}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.specific_subscriber.status"
                      label="For Specific Subs.?"
                      title="Is applied for Specific Subs.?"
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => {
                  if (formData.configs.specific_subscriber.status) {
                    setSpecSubSx(6);
                    if (specificSubscriber?.length === 0) {
                      setSpecificSubscriber(
                        formData.configs.specific_subscriber?.subscribers?.map(
                          (subs) => subs.phone
                        )
                      );
                    }
                    return (
                      <Grid item xs={6}>
                        <Button
                          label="CSV File"
                          data-testid="csv-file"
                          variant="contained"
                          onClick={handleOpenCsvDialog}
                        >
                          <PostAddIcon />
                        </Button>
                        <SpecSubCsvFile
                          openCsvDialog={openCsvDialog}
                          handleCloseCsvDialog={handleCloseCsvDialog}
                          uploadedCsv={uploadedCsv}
                          setUploadedFileCsv={setUploadedFileCsv}
                          specificSubscriber={specificSubscriber}
                          handleUploadCsv={handleUploadCsv}
                          fileCsv={fileCsv}
                          setFileCsv={setFileCsv}
                          today={today}
                        />
                      </Grid>
                    );
                  } else {
                    setSpecSubSx(12);
                  }
                }}
              </FormDataConsumer>

              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.ever_productive_subscriber"
                      label="For Ever Productive Subs. ?"
                      title="Subs. who have done their first purchase"
                      data-testid="input-ever-productive-subscriber"
                      disabled={record.start < today}
                      checked={isProductiveSubs}
                      onChange={(event) => {
                        setIsProductiveSubs(event.target.checked);
                        setIsNewSubs(false);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.registered_subscriber"
                      label="For Registered Subs. ?"
                      title="Subs. who has registered"
                      data-testid="input-registered-subscriber"
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <BooleanInput
                      source="configs.comeback_subscriber"
                      label="For Comeback Subs. ?"
                      title="Subs. who has not purchase more than 30 days"
                      data-testid="input-comeback-subscriber"
                      checked={isComebackSubs}
                      onChange={(e) => {
                        setIsComebackSubs(e.target.checked);
                        setIsNewSubs(false);
                      }}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      fullWidth
                      className={classes.input}
                      source="configs.max_usage_per_subscriber"
                      data-testid="max_usage_per_subscriber"
                      label="Max Usage per Subs."
                      validate={required()}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      fullWidth
                      className={classes.input}
                      source="configs.total_usage"
                      data-testid="total_usage"
                      label="Max Total Usage"
                      validate={required()}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FunctionField
                  render={(record: RaRecord) => (
                    <TextInput
                      fullWidth
                      className={classes.input}
                      source="value"
                      data-testid="value"
                      label="Value"
                      validate={required()}
                      disabled={record.start < today}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid From"
                  source="start"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid Until"
                  source="end"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

const ItemGroupAutocomplete = ({ setSelectedItemGroup, today, ...props }) => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  const record = useRecordContext();

  useEffect(() => {
    const tes = record.items.item_groups
      ?.map((v) => ({
        id: v,
        name: v,
      }))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    setSelectedItemGroup(tes);
  }, [setSelectedItemGroup, record.items.item_groups]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedItemGroup}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        props.setFirstRender(false);
        setSelectedItemGroup(v);
        props.setSelectedItemKey([]);
        setValue(`configs.dirty`, true, { shouldDirty: true });
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      disabled={record.start < today}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Group`}
        />
      )}
    />
  );
};

const ItemKeyAutocomplete = ({ setSelectedItemKey, today, ...props }) => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  const record = useRecordContext();

  useEffect(() => {
    const tes = record.items.item_keys?.map((v) => ({
      id: v,
      name: v,
    }));
    setSelectedItemKey(tes);
  }, [setSelectedItemKey, record.items.item_keys]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedItemKey}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        setSelectedItemKey(v);
        setValue(`configs.dirty`, true, { shouldDirty: true });
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      disabled={record.start < today}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Key`}
        />
      )}
    />
  );
};

const BonusCodeInput = ({ setBonusCode, disabled, ...props }) => {
  return (
    <MuiTextField
      fullWidth
      value={props.value}
      label="Bonus Code"
      defaultValue={props.value}
      onChange={(e) =>
        setBonusCode(e.target.value)
      }
      disabled={disabled}
    />
  );
};

const ItemTargetGroupAutocomplete = ({ setSelectedTargetItemGroup, today, ...props }) => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  const record = useRecordContext();

  useEffect(() => {
    const tes = record.items?.bonus?.item_groups
      ?.map((v) => ({
        id: v,
        name: v,
      }))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    if (tes) setSelectedTargetItemGroup(tes);
  }, [setSelectedTargetItemGroup, record.items?.bonus?.item_groups]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedTargetItemGroup}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        props.setFirstRender(false);
        setSelectedTargetItemGroup(v);
        props.setSelectedTargetItemKey([]);
        setValue(`configs.dirty`, true, { shouldDirty: true });
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      disabled={record.start < today}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Group`}
        />
      )}
    />
  );
};

const ItemTargetKeyAutocomplete = ({ setSelectedTargetItemKey, today, ...props }) => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  const record = useRecordContext();

  useEffect(() => {
    const tes = record.items?.bonus?.item_keys?.map((v) => ({
      id: v,
      name: v,
    }));
    if (tes) setSelectedTargetItemKey(tes);
  }, [setSelectedTargetItemKey, record.items?.bonus?.item_keys]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedTargetItemKey}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        setSelectedTargetItemKey(v);
        setValue(`configs.dirty`, true, { shouldDirty: true });
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      disabled={record.start < today}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Key`}
        />
      )}
    />
  );
};


const SpecSubCsvFile = ({
  openCsvDialog,
  handleCloseCsvDialog,
  uploadedCsv,
  setUploadedFileCsv,
  specificSubscriber,
  handleUploadCsv,
  fileCsv,
  setFileCsv,
  today,
}) => {
  const { getValues, setValue } = useFormContext();
  const start = getValues("start");
  return (
    <Dialog open={openCsvDialog} onClose={handleCloseCsvDialog}>
      <DialogTitle>CSV File</DialogTitle>
      <DialogContent dividers>
        <SimpleForm toolbar={<ToolbarCsvDialog />}>
          <Button
            startIcon={<DescriptionIcon />}
            onClick={uploadedCsv}
            label={
              specificSubscriber?.length
                ? "See Uploaded CSV"
                : "Download Master CSV"
            }
            variant="outlined"
          />
          <br />
          {specificSubscriber?.length ? (
            <DialogContentText>
              <ReactJson
                src={specificSubscriber || []}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={true}
                groupArraysAfterLength={10}
                name={false}
              />
              <br />
            </DialogContentText>
          ) : (
            ""
          )}
          {start < today ? (
            ""
          ) : (
            <FileInput
              source="files"
              options={{ multiple: false }}
              onChange={(e) => {
                setFileCsv(e);
                const filenameArray = e?.name.split(".");
                filenameArray?.pop();
                setUploadedFileCsv(filenameArray?.join("."));
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          )}
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCsvDialog}>Close</Button>
        <Button
          onClick={() => {
            handleUploadCsv(setValue);
            setValue(`configs.dirty`, true, { shouldDirty: true });
          }}
          startIcon={<UploadFileIcon />}
          label="Upload"
          autoFocus
          disabled={!fileCsv}
        />
      </DialogActions>
    </Dialog>
  );
};
