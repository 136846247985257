// @ts-nocheck
import React, { useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  useRecordContext,
  DateTimeInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardMedia, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import { RichTextInput } from "ra-input-rich-text";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CurrentImage = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <>
      <p>Image</p>
      <Card sx={{ display: "inline-block" }}>
        <CardMedia
          component="img"
          image={record.content.image}
          alt={record.content.title}
        />
      </Card>
    </>
  );
};

export const EditBroadcast = ({ onCancel, id, ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    await dataProvider.update("promotionsBroadcast", { data, id }).then(() => {
      notify("ra.notification.created", {
        type: "info",
        messageArgs: { smart_count: 1 },
        undoable: true,
      });
      redirect(`/promotions/broadcast`);
      refresh();
      Swal.fire("Success!", "", "success");
    });
  };

  const ToolbarEdit = () => {
    return (
      <Toolbar classes={useStyles()}>
        <SaveButton />
      </Toolbar>
    );
  };

  return (
    <Edit resource="promotionsBroadcast" title=" &rsaquo; Edit" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        height="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm onSubmit={onSubmitHandler} toolbar={<ToolbarEdit />}>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CurrentImage />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="content.title"
                  data-testid="content-title"
                  label="Title"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimeInput fullWidth source="scheduled_at" label="Schedule At"/>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="content.url"
                  data-testid="content-url"
                  label="URL"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="content.content"
                  data-testid="content-content"
                  label="Content"
                  validate={required()}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
