import { useRetrievePermission } from '../../../hooks/useRetrievePermission';
import { Button, Chip, Drawer, Stack, Typography } from '@mui/material';
import { filter, get } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react'
import { AutocompleteArrayInput, ChipField, Datagrid, DateField, FilterButton, FunctionField, List, RaRecord, TextField, TopToolbar, useListContext } from 'react-admin';
import ReactJson from 'react-json-view';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import EditIcon from "@mui/icons-material/Edit";
import { CreateServiceRewards } from './CreatePage';
import { EditServiceReward } from './EditPage';

const baseUrl = '/services/rewards';

const DateRange = (props: any) => {
    const { filterValues, displayedFilters, setFilters } = useListContext();
    const [dateValue, setDateValue] = useState<any>({
        dateGte: filterValues.dateGte ? moment(filterValues.dateGte).startOf("day").format() : null,
        dateLte: filterValues.dateLte ? moment(filterValues.dateLte).endOf("day").format() : null,
    });

    const dateBegin = filterValues.dateGte ? new Date(moment(filterValues.dateGte).startOf("day").format()) : null;
    const dateEnd = filterValues.dateLte ? new Date(moment(filterValues.dateLte).endOf("day").format()) : null;

    return (
        <DateRangePicker 
            defaultValue={dateBegin && dateEnd ? [dateBegin, dateEnd ] : null}
            value={ dateValue.dateGte && dateValue.dateLte? [new Date(dateValue.dateGte), new Date(dateValue.dateLte)] : null}
            onClean={() => {
                setDateValue({
                    dateGte: null,
                    dateLte: null,
                });
                const updateFilter = {
                    ...filterValues,
                    dateGte: null,
                    dateLte: null
                };
                setFilters(updateFilter, displayedFilters);
            }}
            onChange={(value) => {
                const start = value ? moment(value[0].toString()).startOf("day").format() : null
                const end = value ? moment(value[1].toString()).endOf("day").format() : null

                setDateValue({
                  dateGte: start,
                  dateLte: end,
                });
                const updateFilter = {
                    ...filterValues,
                    dateGte: start,
                    dateLte: end
                };
                setFilters(updateFilter, displayedFilters);
              }}
        />
    )
}

const ListActions = ({filters}: any) => {
    const [isSuperAdmin, isAdmin] = useRetrievePermission();

    return (
        <TopToolbar>
            <FilterButton filters={filters} />
            {(isSuperAdmin || isAdmin) && <Button
                color="primary"
                size="small"
                data-testid="create_button"
                component={Link}
                to={{
                    pathname: `${baseUrl}/create`,
                }}
            >
                + Add New
            </Button>}
        </TopToolbar>
    )
}

const RewardFilters = [
    <DateRange alwaysOn />,
    <AutocompleteArrayInput
        variant='outlined'
        size='small'
      label="Delivery Mode"
      source="delivery_mode"
      choices={[
        { id: "VOUCHER-CODE", name: "VOUCHER-CODE" },
        { id: "COUPON-CODE", name: "COUPON-CODE" },
        { id: "AUTO-ORDER", name: "AUTO-ORDER" },
      ]}
      data-testid="search-delivery-mode"
    />,
    <AutocompleteArrayInput
      label="Service"
      variant='outlined'
      size='small'
      source="availability_by_service"
      choices={[
        { id: "G2", name: "G2" },
        { id: "M21", name: "M21" },
        { id: "GHK", name: "GHK" },
      ]}
      data-testid="search-service-availability"
    />
];

export const RewardsList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSuperAdmin, isAdmin] = useRetrievePermission();

    const matchCreate = matchPath(
        `${baseUrl}/create`,
        location.pathname
    );

    let matchEdit = null;
    if (!location.pathname.endsWith("create")){
        matchEdit = matchPath(`${baseUrl}/:id`, location.pathname);
    }

    const onCloseDrawer = useCallback(() => {
        navigate(baseUrl)
    }, [navigate]);

  return (
    <>
        <List
            resource='rewardCatalogues'
            sort={{ field: "created_at", order: "DESC" }}
            empty={false}
            title="Service &rsaquo; Reward"
            filters={RewardFilters}
            actions={<ListActions filters={RewardFilters} />}
        >
            <Datagrid
                bulkActionButtons={false}
            >
                <DateField
                    source="created_at"
                    label="Created"
                    showTime
                    locales="en-HK"
                    options={{
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                    }}
                />
                <ChipField source='delivery_mode' />
                <FunctionField 
                    label="Availability By Services" 
                    render={(record: any) => {
                        const services = get(record, 'availability_by_service', [])
                        return services.map((service: string) => <Chip 
                            style={{ margin: "0px 5px 5px 5px" }}
                            size="small" 
                            label={service} 
                            key={service} 
                        />)
                    }} 
                />
                <TextField source='item_detail.item.category' label="Category"/>
                <FunctionField label="Codes" render={(record: RaRecord) => {
                    const codes = get(record, 'item_detail.codes', [])
                    return <>
                        <Stack direction="row" spacing={1}>
                            <Chip size='small' color='info' label={<>Redeemed: <b>{filter(codes, (rec) => !!rec.redeemed).length}</b></> } />
                            <Chip size='small' color='secondary' label={<>Purchased: <b>{filter(codes, (rec) => !!rec.purchased).length}</b></> } />
                            <Chip size='small' label={<>Total: <b>{codes.length}</b></> } />
                        </Stack>
                    </>
                }}/>
                <FunctionField
                    label="Item Label"
                    onClick={(e: any) => e.stopPropagation()}
                    render={(record: RaRecord) => (
                    <div>
                        <label>
                            <ReactJson
                                src={record.item_label || {}}
                                collapsed={true}
                                name={false}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                            />
                        </label>
                    </div>
                    )}
                />
                {(isSuperAdmin || isAdmin) && <FunctionField
                    render={(record: RaRecord) => {
                        return (
                            <Button
                                color="primary"
                                size="small"
                                data-testid="edit_button"
                                component={Link}
                                to={{
                                    pathname: `${baseUrl}/${record.id}`,
                                }}
                            >
                                <EditIcon fontSize='small' /> Edit
                            </Button>
                        );
                    }}
                    source="content"
                    label="Action"
                    sortable={false}
                />}
            </Datagrid>
        </List>
        <Drawer
            variant="persistent"
            open={!!matchCreate || !!matchEdit}
            anchor="right"
            onClose={onCloseDrawer}
        >
            {!!matchCreate && <CreateServiceRewards onClose={onCloseDrawer}/>}
            {!!matchEdit && <EditServiceReward
                id={(matchEdit as any).params.id} 
                onClose={onCloseDrawer} />}
        </Drawer>
    </>
  )
}
