import { MultiProviders } from "./MultiProviders";
import { UsersProviderClass } from "./users";
import { FaqsProviderClass } from "./faqs";
import { DiasporasEmbassyClass } from "./diasporaEmbassy";
import { DiasporasEmergencyProviderClass } from "./diasporaEmergency";
import MbaaS from "./Mbaas";
import { TncProviderClass } from "./tnc";
import { DiasporasHolidayProviderClass } from "./diasporaHoliday";
import { DiasporasLatestNewsProviderClass } from "./diasporaLatestNews";
import { SubscribersProviderClass } from "./subscribers";
import { SmsLogsProviderClass } from "./smsLogs";
import { VoiceLogsProviderClass } from "./voiceLogs";
import { PurchaseLogsProviderClass } from "./purchaseLogs";
import { VirtualMsisdnProviderClass } from "./virtualMsisdn";
import { ServicesProductProviderClass } from "./servicesProduct";
import { ServicesTariffsClass } from "./servicesTariffs";
import { ReloadProviderClass } from "./reloadLogs";
import { ProductCatalogsItemGroupProviderClass } from "./productCatalogsItemGroup";
import { ServicesPackageProviderClass } from "./servicesPackage";
import { PromotionsBannerProviderClass } from "./promotionsBanner";
import { PromotionsBroadcastProviderClass } from "./promotionsBroadcast";
import { PromotionsFlashsaleProviderClass } from "./promotionsFlashsale";
import { ConstraintsLimitProviderClass } from "./constraintsLimit";
import { ServicesReportsProviderClass } from "./reports";
import { PromotionsReferralBenefitsProviderClass } from "./promotionsReferralBenefits";
import { DocumentsBatchProviderClass } from "./documentsBatch";
import { MsisdnHistoriesProviderClass } from "./msisdnHistories";
import { CurrencyRatesProviderClass } from "./currencyRates";
import { ExecutionLogsProviderClass } from "./executionLogs";
import { CurrencyMsisdnProviderClass } from "./currencyMsisdn";
import { SystemConfigsProviderClass } from "./systemConfigs";
import { FirebaseRemoteConfigsProviderClass } from "./firebaseRemoteConfigs";
import { VirtualMsisdnVoiceLogsProviderClass } from "./virtualMsisdnVoiceLogs";
import { DeviceLogsProviderClass } from "./deviceLogs";
import { DashboardProviderClass } from "./dashboard";
import { PrivacyPolicyProviderClass } from "./privacyPolicy";
import { OverrideProviderClass } from "./override";
import { OtpConstraintsProviderClass } from "./otpConstraints";
import { OverrideReloadProviderClass } from "./overrideReload";
import { WalletLedgersProviderClass } from "./walletLedgers";
import { ServicesMvnoProviderClass } from "./servicesMvno";
import { MvnoProviderClass } from "./mvno";
import { WalletsProviderClass } from "./wallets";
import { PointRulesProviderClass } from "./pointRules";
import { ServicesRewardProviderClass } from "./servicesReward";
import { UsageRewardProviderClass } from "./rewardLogs";
import { ProductCatalogsProviderClass } from "./productCatalogs";
export { Auth as authProvider } from "./auth";

/* -------------------------------------------------------------------------- */
/*                         Data Provider (Aggregator)                         */
/* -------------------------------------------------------------------------- */
const aggregator = new MultiProviders();
/* ------------------------- Register each provider ------------------------- */
aggregator.register("users", new UsersProviderClass(MbaaS.client));
aggregator.register("faqs", new FaqsProviderClass(MbaaS.client));
aggregator.register("tnc", new TncProviderClass(MbaaS.client));
aggregator.register(
  "privacypolicy",
  new PrivacyPolicyProviderClass(MbaaS.client)
);
aggregator.register("diasporaEmbassy", new DiasporasEmbassyClass(MbaaS.client));
aggregator.register(
  "diasporaEmergency",
  new DiasporasEmergencyProviderClass(MbaaS.client)
);
aggregator.register(
  "diasporaHoliday",
  new DiasporasHolidayProviderClass(MbaaS.client)
);
aggregator.register(
  "diasporaNews",
  new DiasporasLatestNewsProviderClass(MbaaS.client)
);
aggregator.register("subscribers", new SubscribersProviderClass(MbaaS.client));
aggregator.register("walletLedgers", new WalletLedgersProviderClass(MbaaS.client));
aggregator.register("wallets", new WalletsProviderClass(MbaaS.client));
aggregator.register("smsLogs", new SmsLogsProviderClass(MbaaS.client));
aggregator.register("voiceLogs", new VoiceLogsProviderClass(MbaaS.client));
aggregator.register(
  "purchaseLogs",
  new PurchaseLogsProviderClass(MbaaS.client)
);
aggregator.register(
  "mvno", 
  new MvnoProviderClass(MbaaS.client)
)
aggregator.register(
  "servicesMvno",
  new ServicesMvnoProviderClass(MbaaS.client)
);
aggregator.register(
  "servicesProduct",
  new ServicesProductProviderClass(MbaaS.client)
);
aggregator.register("servicesTariffs", new ServicesTariffsClass(MbaaS.client));
aggregator.register(
  "productCatalogsItemGroup",
  new ProductCatalogsItemGroupProviderClass(MbaaS.client)
);
aggregator.register(
  "servicesPackage",
  new ServicesPackageProviderClass(MbaaS.client)
);
aggregator.register(
  "promotionsBanner",
  new PromotionsBannerProviderClass(MbaaS.client)
);
aggregator.register(
  "promotionsBroadcast",
  new PromotionsBroadcastProviderClass(MbaaS.client)
);
aggregator.register(
  "promotionsFlashsale",
  new PromotionsFlashsaleProviderClass(MbaaS.client)
);
aggregator.register(
  "promotionsReferralBenefits",
  new PromotionsReferralBenefitsProviderClass(MbaaS.client)
);
aggregator.register(
  "constraintsLimit",
  new ConstraintsLimitProviderClass(MbaaS.client)
);
aggregator.register(
  "virtualMsisdn",
  new VirtualMsisdnProviderClass(MbaaS.client)
);
aggregator.register(
  "currencyRates",
  new CurrencyRatesProviderClass(MbaaS.client)
);
aggregator.register(
  "systemConfigs",
  new SystemConfigsProviderClass(MbaaS.client)
);
aggregator.register(
  "firebaseRemoteConfigs",
  new FirebaseRemoteConfigsProviderClass(MbaaS.client)
);
aggregator.register("reports", new ServicesReportsProviderClass(MbaaS.client));
aggregator.register(
  "documentsBatch",
  new DocumentsBatchProviderClass(MbaaS.client)
);
aggregator.register("reloadLogs", new ReloadProviderClass(MbaaS.client));
aggregator.register(
  "msisdnHistories",
  new MsisdnHistoriesProviderClass(MbaaS.client)
);
aggregator.register(
  "executionLogs",
  new ExecutionLogsProviderClass(MbaaS.client)
);
aggregator.register(
  "currencyMsisdn",
  new CurrencyMsisdnProviderClass(MbaaS.client)
);
aggregator.register(
  "virtualMsisdnVoiceLogs",
  new VirtualMsisdnVoiceLogsProviderClass(MbaaS.client)
);
aggregator.register("override", new OverrideProviderClass(MbaaS.client));
aggregator.register(
  "overrideReload",
  new OverrideReloadProviderClass(MbaaS.client)
);
aggregator.register("deviceLogs", new DeviceLogsProviderClass(MbaaS.client));
aggregator.register("dashboard", new DashboardProviderClass(MbaaS.client));
aggregator.register(
  "otpConstraints",
  new OtpConstraintsProviderClass(MbaaS.client)
);
aggregator.register("pointRules", new PointRulesProviderClass(MbaaS.client));
aggregator.register("rewardCatalogues", new ServicesRewardProviderClass(MbaaS.client));
aggregator.register("rewardLogs", new UsageRewardProviderClass(MbaaS.client));
aggregator.register("productCatalogs", new ProductCatalogsProviderClass(MbaaS.client));

export const dataProvider = aggregator.serve();
