import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import { dataProvider } from ".";
export class ServicesPackageProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (!params.filter.isMvno) {
      filterArray.push({
        is_mvno: {
          _eq: false,
        },
      });
    }
    if (!params.filter.isVas) {
      filterArray.push({
        is_vas: {
          _eq: false,
        },
      });
    }
    if (params.filter.id) {
      const {
        data: { item_group: itemGroup },
      } = await dataProvider.getOne("productCatalogsItemGroup", {
        id: params.filter.id,
      });
      filterArray.push({
        item_group: {
          _eq: itemGroup,
        },
      });
    }
    if (params.filter.label) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.label}%`,
        },
      });
    }
    if (params.filter.packageIds) {
      filterArray.push({
        id: {
          _in: params.filter.packageIds,
        },
      });
    }
    if (params.filter.q) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      sort: field || "item_group",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query ServicesPackageList($where: ProductCatalogsWhereInput) {
          allProductCatalogsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              item_key
              item_group
              label
              description
              retail_price
              inquiry_rules
              execution_rules
              is_available
              is_mvno
              is_vas
              vas_sync_time
              vas_sync_detail
              constraint_id
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allProductCatalogsList?.data,
      total: queryResult?.allProductCatalogsList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aServicePackage($id: UUID!) {
          getProductCatalogsById(id: $id) {
            id
            created_at
            updated_at
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            is_mvno
            is_vas
            vas_sync_time
            vas_sync_detail
            product_pricings_product_catalog_id_list {
              data {
                id
                created_at
                updated_at
                product_catalog_id
                country
                schema
                value
              }
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: queryResult?.getProductCatalogsById };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
      retail_price: Number(params.data.retail_price),
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    delete toBeUpdatedData.product_pricings_product_catalog_id_list;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ProductCatalogsUpdateInput!) {
          updateProductCatalogsById(ids: $ids, input: $input) {
            id
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            constraint_id
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return { data: queryResult?.updateProductCatalogsById[0] } as UpdateResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });
    const toBeCreatedData: any = {
      ...params.data,
      retail_price: Number(params.data.retail_price),
      is_vas: false,
      is_mvno: false,
      inquiry_rules: !params.data.inquiry_rules
        ? {}
        : params.data.inquiry_rules,
      execution_rules: !params.data.execution_rules
        ? {}
        : params.data.execution_rules,
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [ProductCatalogsCreateInput!]!) {
          createProductCatalogs(input: $input) {
            id
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            is_mvno
            is_vas
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createProductCatalogs[0] } as CreateResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    console.log({ method: "delete", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteProductCatalogsById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return { data: queryResult?.deleteProductCatalogsById[0] } as DeleteResult;
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams<any>
  ): Promise<DeleteManyResult<any>> {
    console.log({ method: "deleteMany", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteProductCatalogsById(ids: $ids)
        }
      `,
      variables: {
        ids: params.ids,
      },
    });
    console.log({
      method: "deleteMany",
      resource,
      params,
      returnedData: queryResult?.deleteProductCatalogsById,
    });

    return {
      data: params.ids,
    } as DeleteManyResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          id: {
            _in: params.ids,
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query ServicesPackageMany($where: ProductCatalogsWhereInput) {
          allProductCatalogsList(where: $where) {
            data {
              id
              label
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allProductCatalogsList?.data,
    };
  }
}
